<template>
    <div class="doc-base-litem">
        <div class="doc-base-litem__row">
            <div
                v-if="is_editor"
                class="doc-base-litem__col doc-base-litem__col--left hidden"
            >
                <label class="css-control css-control-sm css-control-secondary css-checkbox">
                    <input
                        type="checkbox"
                        name="selected"
                        class="css-control-input js-show-actions"
                    >
                    <span class="css-control-indicator"></span>
                </label>
            </div>

            <div class="doc-base-litem__col doc-base-litem__col--right">
                <div class="doc-base-litem__desc">
                    <a
                        :href="document.link"
                        target="_blank"
                        class="doc-base-litem__name"
                    >
                        <span v-html="document.name"></span>
                    </a>

                    <div class="doc-base-litem__dataset">
                        <div
                            v-if="document.number !== 'null' && document.number !== null"
                            class="doc-base-litem__data"
                        >
                            Номер:
                            <span>{{ document.number }}</span>
                        </div>

                        <div
                            v-if="document.date_approval"
                            class="doc-base-litem__data"
                        >
                            Дата:
                            <span>{{ document.date_approval | moment('DD MMMM YYYY') }}</span>
                        </div>

                        <div class="doc-base-litem__data">
                            Создан/Изменён:
                            <span>{{ document.date_change }}</span>
                        </div>
                    </div>
                </div>

                <div
                    v-if="!is_editor"
                    class="doc-base-litem__statuses"
                >
                    <button
                        v-if="document.study_status === 1"
                        class="doc-base-litem__status v-btn v-btn--red-bordered"
                        @click="onStudied"
                    >
                        <icon class="mr-10 material__learning_button-red-icon" width="18" height="18">
                            <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9" cy="9" r="8.25" stroke="#e32b2b" stroke-width="1.5" />
                                <path d="M9 4L9 9.0625M9 10.75L9 13" stroke="#e32b2b" stroke-width="2.5" />
                            </svg>
                        </icon>
                        <span class="ml-10">Не изучен</span>
                    </button>

                    <router-link
                        v-if="document.study_status === 2"
                        class="doc-base-litem__status v-btn v-btn--gold"
                        :to="{ name: 'UserTesting', query: { type: 'document', id: document.id }}"
                    >
                        <icon width="15" height="13">
                            <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 6.32895L5.30519 11L14 1" stroke="#fff" stroke-width="2" />
                            </svg>
                        </icon>
                        <span class="ml-10">Пройти тестирование</span>
                    </router-link>

                    <button
                        v-if="document.study_status === 2"
                        class="doc-base-litem__status doc-base-litem__status--passed v-btn v-btn--not-ready"
                    >
                        <icon width="15" height="13" class="material__learning_button-green-icon">
                            <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 6.32895L5.30519 11L14 1" stroke="#50b440" stroke-width="2" />
                            </svg>
                        </icon>
                        <span class="ml-10">Изучен</span>
                    </button>

                    <span
                        v-if="document.study_status === 3"
                        class="doc-base-litem__status doc-base-litem__status--passed v-btn v-btn--not-ready"
                    >
                        <icon width="15" height="13" class="material__learning_button-green-icon">
                            <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 6.32895L5.30519 11L14 1" stroke="#50b440" stroke-width="2" />
                            </svg>
                        </icon>
                        <span class="ml-10">Тест пройден</span>
                    </span>

                    <span
                        v-if="document.study_status === 1 && document.is_new_version"
                        class="doc-base-litem__status doc-base-litem__status--passed v-btn v-btn--gold-bordered"
                    >
                        <icon width="16" height="16" class="material__learning_button-green-icon">
                            <svg width="16" height="16" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 15C3.3645 15 0 11.6355 0 7.5C0 3.3645 3.3645 0 7.5 0C11.6355 0 15 3.3645 15 7.5C15 11.6355 11.6355 15 7.5 15ZM7.5 1.15365C4.0008 1.15365 1.15365 4.00065 1.15365 7.5C1.15365 10.9994 4.00065 13.8464 7.5 13.8464C10.9994 13.8464 13.8464 10.9994 13.8464 7.5C13.8464 4.00065 10.9994 1.15365 7.5 1.15365ZM8.07716 8.9127V3.64395C8.07716 3.3252 7.81875 3.06679 7.5 3.06679C7.18125 3.06679 6.92285 3.3252 6.92285 3.64395V8.9133C6.92285 9.23205 7.18125 9.49046 7.5 9.49046C7.81875 9.49046 8.07716 9.23205 8.07716 8.9133V8.9127ZM7.50294 10.3605C7.06466 10.3605 6.71837 10.7145 6.71837 11.1451C6.71837 11.5834 7.06466 11.9297 7.50294 11.9297C7.93361 11.9297 8.28752 11.5834 8.28752 11.1451C8.28752 10.7145 7.93361 10.3605 7.50294 10.3605Z" fill="#fbc04f"/>
                            </svg>
                        </icon>
                        <span class="ml-10">Вышла новая редакция</span>
                    </span>
                </div>

                <div
                    v-if="is_editor"
                    class="doc-base-litem__actions"
                >
                    <button
                        type="button"
                        class="doc-base-litem__action doc-base-litem__action--pencil"
                        aria-label="Edit document"
                        title="Редактировать документ"
                        @click="onEditDocument"
                    >
                        <svg
                            width="10"
                            height="10"
                            xmlns="http://www.w3.org/2000/svg"
                            class="doc-base-litem__icon doc-base-litem__icon--pencil"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M9.796 1.176L8.89.264a.897.897 0 00-1.272 0l-.869.873 2.034 2.045 1.014-1.019a.7.7 0 000-.987zm-3.048-.028l2.034 2.045-5.679 5.694L1.07 6.843l5.678-5.695zM.286 9.993a.232.232 0 01-.28-.277l.513-2.32 2.034 2.045-2.267.552z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </button>

                    <button
                        type="button"
                        class="doc-base-litem__action doc-base-litem__action--delete"
                        aria-label="Delete document"
                        title="Удалить документ"
                        @click="onDeleteDocument(document)"
                    >
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                            class="doc-base-litem__icon"
                        >
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.39336 4.80367L0.292969 1.70328L1.70718 0.289062L4.80758 3.38946L7.90797 0.289062L9.32218 1.70328L6.22179 4.80367L9.32218 7.90406L7.90797 9.31828L4.80758 6.21788L1.70718 9.31828L0.292969 7.90406L3.39336 4.80367Z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '../../../api/session';

    export default {
        name: 'ListDocument',
        props: {
            document: {
                type: Object,
                default: {}
            },
            is_editor: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {};
        },
        methods: {
            onEditDocument() {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    this.$router.push(`/documents/edit/${this.document.id}/`);
                }
            },

            onDeleteDocument(document) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    this.$emit('delete', {
                        el: this.$el,
                        data: document
                    });
                }
            },

            async onStudied() {
                this.document.study_status = 2;

                try {
                    await session.post(`/api/v1/document/${this.document.id}/set_studied/`);
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "#sass/v-style";

    .doc-base-litem {
        $bn: &;

        padding: 10px 0;
        &__row {
            margin: -10px;
            display: flex;
            align-items: center;
        }

        &__col {
            margin: 10px;
            display: flex;
            align-items: center;
            &--right {
                flex-grow: 1;
                justify-content: space-between;
                @media screen and (max-width: 769px) {
                    align-items: flex-start;
                }
            }
        }

        &__dataset {
            gap: 0 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 5px;
            font-size: 12px;
            white-space: nowrap;
            color: #a4adba;
            span {
                color: #222;
            }
        }

        &__statuses {
            gap: 10px;
            display: flex;
            flex-wrap: wrap;
        }

        &__status {
            display: flex;
            align-items: center;
            &.doc-base-litem__status--passed {
                cursor: default;
                &:hover {
                    background-color: transparent;
                }
            }
        }

        &__actions {
            display: flex;
            align-items: center;
        }

        &__action {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 0px;
            padding: 0px;
            width: 22px;
            height: 22px;
            fill: #575757;
            border-radius: 100px;
            background-color: #f8f8f8;
            & + #{$bn}__action {
                margin-left: 10px;
            }

            &:hover {
                fill: #fff;
            }

            &--pencil:hover {
                background-color: #ffb71c;
            }
            &--delete:hover {
                background-color: #d0021b;
            }
        }

        &__icon {
            position: relative;
        }
    }

    .v-btn {
        margin: 2.5px 0;
        white-space: nowrap;
    }
</style>
