<template>
    <div class="doc-base__content">
        <div class="doc-base__article">
            <div class="doc-base-header">
                <div class="doc-base-header__top">
                    <a
                        v-if="rubric_name"
                        class="doc-base__back"
                        href="javascript:void(0)"
                        title="Вернуться назад"
                        aria-label="Вернуться назад"
                        @click="previousRubric"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823">
                            <path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"></path>
                        </svg>
                        Назад
                    </a>
                </div>

                <div class="doc-base-header__main">
                    <div class="doc-base-title">
                        <div class="doc-base-title__row">
                            <div class="doc-base-title__col doc-base-title__col--left">
                                <div class="doc-base-name">
                                    <a
                                        v-if="rubric_name"
                                        class="doc-base-name__back"
                                        href="javascript:void(0)"
                                        title="Вернуться назад"
                                        aria-label="Вернуться назад"
                                        @click="previousRubric"
                                    >
                                        <i class="fa fa-angle-left"></i>
                                    </a>

                                    <span
                                        v-if="!rubric_name"
                                    >
                                        Документы
                                    </span>

                                    <span
                                        v-else
                                        :title="rubric_name"
                                    >
                                        {{ rubric_name }}
                                    </span>

                                    <v-popover
                                        v-if="rubric_name"
                                        popoverInnerClass="one-rubric__popover-inner"
                                        popoverClass="one-rubric__popover"
                                        placement="bottom-center"
                                        offset="1"
                                    >
                                        <span
                                            class="doc-base-name__more"
                                            title="Ещё"
                                            aria-label="More"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M4 2a2 2 0 10-4 0 2 2 0 004 0zM11 2a2 2 0 10-4 0 2 2 0 004 0zM18 2a2 2 0 10-4 0 2 2 0 004 0z"
                                                    clip-rule="evenodd"
                                                />
                                            </svg>
                                        </span>

                                        <div class="menu-btn__inner" slot="popover">
                                            <a
                                                href="javascript:void(0)"
                                                class="menu-btn__button one-rubric__change"
                                                v-close-popover
                                                @click="editRubric({ id: rubric_id })"
                                            >
                                                Редактировать
                                            </a>

                                            <button
                                                class="menu-btn__button one-rubric__delete"
                                                type="button"
                                                v-close-popover
                                                @click="modalDeleteRubric({ id: rubric_id, name: rubric_name })"
                                            >
                                                Удалить
                                            </button>
                                        </div>
                                    </v-popover>
                                </div>
                            </div>

                            <div class="doc-base-title__col doc-base-title__col--right">
                                <div class="doc-base-actions">
                                    <button
                                        type="button"
                                        class="doc-base-action v-btn v-btn--excel"
                                        title="Сгенерировать отчёт по документам в виде таблицы Excel"
                                        @click="onOpenDownloadExcel"
                                    >
                                        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.875C0 0.839467 0.873042 0 1.95 0H11.05C12.127 0 13 0.839467 13 1.875V13.125C13 14.1606 12.127 15 11.05 15H1.95C0.873042 15 0 14.1606 0 13.125V1.875ZM6.46595 6.38574L4.90324 4H3.04541L5.42541 7.5L3 11H4.87676L6.46595 8.61084H6.52649L8.11568 11H10L7.58216 7.5L9.94703 4H8.09676L6.52649 6.38574H6.46595Z" fill="#fff"/>
                                        </svg>
                                    </button>

                                    <v-popover
                                        v-if="is_editor"
                                        popoverClass="header__main-add-popover"
                                        class="header__main-add-wrapper"
                                        placement="bottom-end"
                                        offset="10"
                                    >
                                        <button
                                            type="button"
                                            class="doc-base-action header__main-add"
                                            title="Добавить документ или рубрику"
                                        >
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 5H12V7H0V5Z" fill="#fff"></path>
                                                <path d="M7 8.74227e-08L7 12H5L5 0L7 8.74227e-08Z" fill="#fff"></path>
                                            </svg>
                                        </button>

                                        <div
                                            slot="popover"
                                            class="header__main-add-popover-inner"
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                @click="addDocument"
                                            >
                                           <span class="header__main-add-popover-icon">
                                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.833984 2C0.833984 0.895431 1.72941 0 2.83398 0H12.1673C13.2719 0 14.1673 0.895431 14.1673 2V14C14.1673 15.1046 13.2719 16 12.1673 16H2.83398C1.72941 16 0.833984 15.1046 0.833984 14V2ZM3.50068 9C3.50068 8.44771 3.9484 8 4.50068 8H10.5007C11.053 8 11.5007 8.44771 11.5007 9C11.5007 9.55229 11.053 10 10.5007 10H4.50068C3.9484 10 3.50068 9.55229 3.50068 9ZM4.50068 4C3.9484 4 3.50068 4.44771 3.50068 5C3.50068 5.55228 3.9484 6 4.50068 6H7.83401C8.3863 6 8.83401 5.55228 8.83401 5C8.83401 4.44771 8.3863 4 7.83401 4H4.50068Z" fill="#989CAE"></path>
                                                </svg>
                                            </span>
                                                Документ
                                            </a>

                                            <a
                                                v-if="current_user.is_admin"
                                                href="javascript:void(0)"
                                                @click="addRubric"
                                            >
                                            <span class="header__main-add-popover-icon">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g>
                                                        <path d="M3.73343 6.26667C3.66569 6.26667 3.60569 6.31253 3.58809 6.37787L0.533425 14V14.2667C0.533425 14.4624 0.520892 14.5333 0.684092 14.5333H12.6198C12.9281 14.5333 13.1996 14.3256 13.2732 14.0499L16.0001 6.53333C16.0001 6.53333 16.0001 6.36667 16.0001 6.26667H3.73343Z" fill="#989CAE"></path>
                                                        <path d="M3.39493 5.73333H14.1333H14.4V4.06187C14.4 3.66 14.0731 3.33333 13.6715 3.33333H7.07067L5.73733 1.46667H0.728533C0.326933 1.46667 0 1.7936 0 2.1952V13.3408L2.74187 6.21627C2.8152 5.94107 3.08667 5.73333 3.39493 5.73333Z" fill="#989CAE"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                                Рубрика
                                            </a>
                                        </div>
                                    </v-popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="doc-base-header__footer">
                    <div
                        v-show="rubrics.length !== 0 || documents.length !== 0 || search"
                        class="header__search"
                    >
                        <input
                            type="text"
                            placeholder="Поиск"
                            class="header__search-input"
                            v-model="search"
                        >
                    </div>
                </div>
            </div>

            <div
                class="doc-base__main"
                id="result_list_container"
            >
                <div v-if="rubrics.length > 0">
                    <div
                        v-for="(item, index) in rubrics"
                        v-if="item.is_rubric"
                        :key="`rubric_${index}_${item.id}`"
                        class="doc-base-block"
                    >
                        <div class="doc-base-block__header">
                            <caption-document
                                class="doc-base-caption--main"
                                :caption-data="item"
                                @following="loadRubric(item.id)"
                                @edit="editRubric(item)"
                                @delete="modalDeleteRubric(item)"
                            />
                        </div>

                        <div class="doc-base-block__main">
                            <div
                                v-if="item.inline_rubrics.length > 0"
                                v-for="inlineRubricItem in item.inline_rubrics"
                                :key="`inline-rubric_${inlineRubricItem.id}`"
                                class="doc-base__box"
                            >
                                <caption-document
                                    v-if="inlineRubricItem.is_rubric == true"
                                    class="doc-base-caption--sub"
                                    :caption-data="inlineRubricItem"
                                    @following="loadRubric(inlineRubricItem.id)"
                                    @edit="editRubric(inlineRubricItem)"
                                    @delete="modalDeleteRubric(inlineRubricItem)"
                                />

                                <div
                                    v-if="inlineRubricItem.documents.length > 0"
                                    class="doc-base__list doc-base__list--sub"
                                >
                                    <list-document
                                        v-for="(document) in inlineRubricItem.documents"
                                        :key="`document_${inlineRubricItem.id}_${document.id}`"
                                        :document="document"
                                        :is_editor="is_editor"
                                        @delete="onDeleteDocument"
                                    ></list-document>
                                </div>

<!--                                <button-->
<!--                                    v-if="inlineRubricItem.count !== inlineRubricItem.documents.length"-->
<!--                                    type="button"-->
<!--                                    class="doc-base__more doc-base__more&#45;&#45;sub base-btn"-->
<!--                                    @click="loadMore(inlineRubricItem)"-->
<!--                                >-->
<!--                                    Показать ещё-->
<!--                                </button>-->
                            </div>

                            <div
                                v-if="item.documents.length > 0"
                                class="doc-base__box"
                            >
                                <div class="doc-base__list">
                                    <list-document
                                        v-for="(document) in item.documents"
                                        :key="`document_${item.id}_${document.id}`"
                                        :document="document"
                                        :is_editor="item.is_access_edit"
                                        @delete="onDeleteDocument"
                                    ></list-document>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="item.count !== item.documents.length"
                            class="doc-base-block__footer"
                        >
                            <button
                                type="button"
                                class="base-btn doc-base__more"
                                @click="loadMore(item)"
                            >
                                Показать еще
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    v-if="documents.length > 0"
                    class="doc-base-block doc-base-block--documents"
                >
                    <div class="doc-base-block__main">
                        <div class="doc-base__box">
                            <div class="doc-base__list">
                                <list-document
                                    v-for="(document, index) in documents"
                                    :key="`document_${index}_${document.id}`"
                                    :document="document"
                                    :is_editor="is_editor"
                                    @delete="onDeleteDocument"
                                ></list-document>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Поиск не дал результатов-->
                <div
                    v-if="
                        (search && rubrics.length === 0 && documents.length === 0) ||
                        (rubrics.length === 0 && documents.length === 0 && !is_editor)
                    "
                    v-show="!isLoading"
                    class="doc-base-empty"
                >
                    <div class="doc-base-empty__content">
                        <svg class="doc-base-empty__icon" width="112" height="112" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M56 112c30.928 0 56-25.072 56-56S86.928 0 56 0 0 25.072 0 56s25.072 56 56 56z"
                                  fill="#FFF9EC"/>
                            <path d="M61.494 64.714l1.378 1.378 1.611 1.603.621.626 3.215-3.218-3.604-3.61-3.22 3.22z"
                                  fill="#5D6D7E"/>
                            <path
                                d="M71.598 65.082l11.094 11.094a4.577 4.577 0 011.349 3.258 4.595 4.595 0 01-4.607 4.607 4.591 4.591 0 01-3.258-1.347L65.082 71.599l6.516-6.517z"
                                fill="#FF8E31"/>
                            <path
                                d="M70.067 66.615l.901-.903 11.096 11.095a3.687 3.687 0 011.085 2.626c0 .995-.386 1.93-1.085 2.632a.643.643 0 01-.906-.002.64.64 0 010-.9 2.43 2.43 0 00.716-1.731 2.411 2.411 0 00-.713-1.722L70.067 66.615z"
                                fill="#FECE59"/>
                            <path
                                d="M65.082 71.599l6.516-6.517-.486-.487a1.598 1.598 0 00-1.14-.474c-.45 0-.851.178-1.142.471l-4.237 4.24a1.599 1.599 0 00-.472 1.14c0 .442.181.85.472 1.136l.489.49zM27.959 48.788c0-11.502 9.326-20.83 20.83-20.83 11.505 0 20.83 9.329 20.83 20.83 0 11.505-9.326 20.83-20.83 20.834-11.504-.003-20.83-9.329-20.83-20.834z"
                                fill="#34495E"/>
                            <path
                                d="M48.789 67.365c10.256 0 18.575-8.316 18.575-18.577 0-10.255-8.319-18.574-18.575-18.574-10.258 0-18.576 8.318-18.576 18.574 0 10.26 8.318 18.577 18.576 18.577z"
                                fill="#5D6D7E"/>
                            <path
                                d="M48.789 65.878c9.436 0 17.088-7.65 17.088-17.09 0-9.434-7.652-17.088-17.088-17.088S31.7 39.354 31.7 48.788c0 9.44 7.651 17.09 17.089 17.09z"
                                fill="#CEE2F2"/>
                            <path
                                d="M49.239 34.506a1.514 1.514 0 00-1.342-.671 14.868 14.868 0 00-6.625 1.99 1.515 1.515 0 00-.546 2.065 1.507 1.507 0 002.06.541 11.942 11.942 0 015.288-1.584 1.497 1.497 0 001.414-1.586 1.428 1.428 0 00-.25-.755zM39.563 39.46a1.5 1.5 0 00-2.418-.107 14.923 14.923 0 00-3.067 6.57c-.553 2.866-.264 5.8.835 8.49a1.509 1.509 0 001.971.822 1.503 1.503 0 00.823-1.965 11.866 11.866 0 01-.659-6.774 11.792 11.792 0 012.438-5.244 1.508 1.508 0 00.077-1.792z"
                                fill="#fff"/>
                        </svg>

                        <div class="doc-base-empty__text">
                            <p
                                v-if="
                                rubrics.length === 0 &&
                                documents.length === 0 &&
                                search &&
                                rubric_id
                            "
                            >
                                Поиск по рубрике не дал результатов. Попробуйте выполнить
                                <a
                                    href="javascript:void(0)"
                                    @click="loadHome"
                                >
                                    поиск
                                </a>
                                на главной странице Документов
                            </p>

                            <p
                                v-if="
                                rubrics.length === 0 &&
                                documents.length === 0 &&
                                search &&
                                !rubric_id"
                            >
                                По вашему запросу ничего не найдено
                            </p>

                            <p
                                v-if="
                                !is_editor &&
                                rubrics.length === 0 &&
                                documents.length === 0 &&
                                !search &&
                                !rubric_id
                            "
                            >
                                Раздел документов пуст
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Пустая рубрика -->
                <div
                    v-if="
                    (rubrics.length === 0 && documents.length === 0 && !search) &&
                    is_editor
                "
                    v-show="!isLoading"
                    class="doc-base-block"
                >
                    <div class="doc-base-block__main">
                        <router-link
                            to="/documents/add/"
                            class="doc-base__add"
                        >
                            + Добавить документ
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <mugen-scroll
            v-if="!isLoadEnd"
            :handler="infiniteScrollHandler"
            :should-handle="!isLoading"
        />

        <loading-spinner
            v-if="isLoading"
            class="doc-base__spinner wall__spinner"
        />

        <loading-spinner
            v-if="isSearchLoading"
            class="doc-base__spinner doc-base__spinner--search wall__spinner"
        />

        <!--        <div-->
        <!--            v-if="documents_active"-->
        <!--            class="doc-base-filter"-->
        <!--            id="actions_block"-->
        <!--        >-->
        <!--            <div class="doc-base-filter__row">-->
        <!--                <div-->
        <!--                    class="doc-base-filter__item doc-base-filter__item&#45;&#45;unchecked"-->
        <!--                >-->
        <!--                    Снять выделенные-->
        <!--                </div>-->

        <!--                <div class="doc-base-filter__item doc-base-filter__item&#45;&#45;delete">-->
        <!--                    <span class="doc-base-filter__icon doc-base-filter__icon&#45;&#45;delete">-->
        <!--                        ×-->
        <!--                    </span>-->
        <!--                    Удалить выделенные-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>


<script>
    import session from '@/api/session'
    import MugenScroll from 'vue-mugen-scroll'
    import LoadingSpinner from '../../LoadingSpinner'
    import { mapState } from 'vuex'
    import { eventBus } from '@/main'
    import debounce from '@/helpers/debounce'
    import DownloadExcelModal from "../modals/DownloadExcelModal.vue";
    import ListDocument from './ListDocument'
    import CaptionDocument from './CaptionDocument'

    export default {
        name: 'List',
        components: {
            MugenScroll,
            LoadingSpinner,
            ListDocument,
            CaptionDocument
        },
        props: {
            load_rubric_id: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                checkbox: [],
                nextData: '/api/v1/document/',
                isLoading: false,
                isSearchLoading: false,
                isLoadEnd: false,
                deleteDocument: [],
                deleteRubric: [],
                level_back: null,
                rubric_name: null,
                rubric_id: null,
                search: '',
                results: []
            }
        },
        created: function() {
            if (this.load_rubric_id) {
                this.loadRubric(this.load_rubric_id)
            } else if (window.location.hash) {
                this.loadRubric(+window.location.hash.slice(1));
            } else {
                this.loadHome()
            }

            this.debouncedSearch = debounce(this.searchData, 500)
        },
        mounted() {
            window.addEventListener('rubric-open', (event) => {
                this.loadRubric(event.detail.rubric_id);
            });
        },
        computed: {
            is_editor() {
                return this.current_user.is_editor_rubric_docs || this.current_user.is_editor_document || this.current_user.is_admin
            },

            rubrics: function () {
                let list = this.results.filter(item => !Array.isArray(item));

                list = list.map(item => {
                    if (item.next === null) {
                        item.is_exists_more = item.count > 2;
                        item.next = '/api/v1/document/load_more/{ID}/'.replace('{ID}', item.id);
                    }

                    return item
                });

                list = list.map(rubric => {
                    let documents = rubric.documents.filter(item => {
                        return !this.deleteDocument.some(elem => elem.id === item.id)
                    });

                    documents.map(item => {
                        item.active = false;
                        return item
                    });
                    rubric.documents = documents;

                    return rubric
                });

                list = list.filter(rubric => {
                    return rubric.documents.length > 0 || rubric.inline_rubrics.length > 0;
                })

                return list.filter(item => {
                    return !this.deleteRubric.some(elem => elem.id === item.id);
                })
            },

            documents: function () {
                const documents = [];
                const array = this.results.filter(item => Array.isArray(item));

                array.forEach(item => {
                    documents.push(...item)
                });

                documents.map(item => {
                    item.active = false;
                    return item
                });

                return documents.filter(item => !this.deleteDocument.some(elem => elem.id === item.id))
            },

            ...mapState('default_data', [
                'current_user'
            ]),
        },
        watch: {
            load_rubric_id: {
                handler: function (val, oldVal) {
                    if (val === 0) {
                        this.loadHome()
                    } else {
                        this.loadRubric(val)
                    }
                },
                deep: true
            },

            search: {
                handler: function (value) {
                    if(value.length >= 3) {
                        this.debouncedSearch()
                    } else if(value.length === 0) {
                        this.debouncedSearch()
                    }
                },
                deep: true
            },
        },
        methods: {
            async editRubric(item) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    const ChangeRubric = () => import(`../../rubric/ChangeRubric`);
                    this.$modal.show(ChangeRubric, {
                        permissionType: 'rubric_document',
                        url_list: `/api/v1/rubric_document/`,
                        rubric_id: item.id
                    }, {
                        name: 'ChangeRubric',
                        adaptive: true,
                        maxWidth: 740,
                        width: '100%',
                        height: 'auto'
                    })
                }
            },
            addDocument() {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    this.$router.push('/documents/add/')
                }
            },
            addRubric() {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    const ChangeRubric = () => import('@/components/rubric/ChangeRubric');

                    this.$modal.show(ChangeRubric, {
                        url_list: `/api/v1/rubric_document/`,
                        permissionType: `rubric_document`
                    }, {
                        name: 'ChangeRubric',
                        adaptive: true,
                        maxWidth: 740,
                        width: '100%',
                        height: 'auto'
                    })
                }
            },
            async searchData() {
                if (this.search && this.rubric_id) {
                    this.nextData = `/api/v1/document/?rubric=${this.rubric_id}&search=${this.search}&page_size=30`;
                } else if (this.search) {
                    this.nextData = `/api/v1/document/?search=${this.search}&page_size=30`;
                } else if (!this.search) {
                    let params = '?page_size=30'

                    if (this.rubric_id) {
                        params += `&rubric=${this.rubric_id}`
                    }

                    this.nextData = `/api/v1/document${params}`;
                }

                this.isSearchLoading = true;

                let data = await this.loadList();

                this.results = [];
                this.results.push(...data);

                this.isSearchLoading = false;
            },
            async loadHome() {
                this.nextData = '/api/v1/document?page_size=30';

                this.$emit('rubricID', 0);

                this.results = [];
                await this.infiniteScrollHandler(true);
            },

            async previousRubric() {
                this.nextData = '/api/v1/document?page_size=30';

                if (this.level_back) {
                    this.nextData = '/api/v1/document/?rubric={ID}'.replace('{ID}', this.level_back);
                    this.$emit('rubricID', this.level_back);
                    eventBus.$emit('item-open-rubric-document', this.level_back);
                } else {
                    this.nextData = '/api/v1/document?page_size=30';
                    this.$emit('rubricID', 0);
                    eventBus.$emit('item-open-rubric-document', 0);
                    if (window.location.hash) {
                        this.$router.push({ name: 'Documents', hash: '' });
                    }
                }

                this.results = [];
                await this.infiniteScrollHandler(true);
            },

            async loadRubric(rubric_id) {
                this.nextData = `/api/v1/document/?rubric=${rubric_id}&page_size=30`;

                this.results = [];
                this.$emit('rubricID', rubric_id);

                await this.infiniteScrollHandler(true);
                eventBus.$emit('item-open-rubric-document', rubric_id);
            },
            async infiniteScrollHandler(access=false) {
                if (this.results.length > 0 || access) {
                    if (!this.nextData) {
                        return false;
                    }

                    this.isLoading = true;
                    const data = await this.loadList();

                    /**
                     * ***** Костыль. *****
                     * Детали: суть костыля заключается в том, что во время загрузки рубрик,
                     * местами прогружаются дубли, чтобы избежать рендеринг дублей, делаем мы следующее: проверяем у results,
                     * есть ли прогруженная рубрика, если есть, то передаём ей только документы из подгруженного дубля.
                     */
                    // const dataRubric = data.find((dataElem, dataIndex, dataArray) => {
                    //     return this.results.find((resultElem) => {
                    //         if (
                    //             dataElem.is_rubric === true &&
                    //             resultElem.id === dataElem.id
                    //         ) {
                    //             dataArray.splice(dataIndex, 1);
                    //             return dataElem;
                    //         }
                    //     });
                    // });
                    //
                    // if (dataRubric) {
                    //     this.results = this.results.map((variable) => {
                    //         if (variable.id === dataRubric.id) {
                    //             variable.documents = variable.documents.concat(
                    //                 dataRubric.documents || []
                    //             );
                    //         }
                    //
                    //         return variable;
                    //     })
                    // }
                    // ***** /Костыль. *****

                    if (data) {
                        this.results.push(...data);
                    }

                    this.isLoading = false;
                }
            },
            async loadMore(item) {
                let params = {
                    rubric_id: item.id
                };
                try {
                    let request = session.get(`/api/v1/document/load_documents/`, {params});
                    let response = await request;
                    item.documents = response.data;
                } catch (err) {
                    this.$swal({
                        title: 'Ошибка!',
                        text: 'При обработке запроса произошла ошибка на сервере',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1400,
                    });
                }
            },
            // async loadMore(item) {
            //     session
            //         .get(item.next)
            //         .then(response => {
            //             let documents = item.documents.concat(response.data.results);
            //             item.documents = [...new Set(documents.map(JSON.stringify))].map(JSON.parse);
            //             item.next = response.data.next;
            //             item.is_exists_more = !!item.next;
            //         })
            //         .catch((err) => {
            //             this.errorHandler(err);
            //         })
            // },
            async loadList() {
                try {
                    const request = session.get(this.nextData);
                    const response = await request;
                    const data = response.data.results;

                    this.level_back = response.data.level_back;
                    this.rubric_id = response.data.rubric_id;
                    this.rubric_name = response.data.rubric_name;

                    if (!response.data.next) {
                        this.isLoadEnd = true
                        return data;
                    }

                    let urlNext = new URL(response.data.next);
                    urlNext = urlNext.pathname + urlNext.search;

                    this.nextData = urlNext;

                    return data;
                } catch (err) {
                    this.errorHandler(err);
                }
            },
            onDeleteDocument({ el, data }) {
                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Удалить документ?',
                    text: document.name,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да, удалить!',
                    cancelButtonText: 'Отменить',
                }).then(async (value) => {
                    if (value.value) {
                        let swal = this.$swal({
                            title: 'Удаление...',
                            icon: 'info',
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                        });

                        // выполнение
                        try {
                            await session.delete(`/api/v1/document/${data.id}/`)

                            this.popDocument(data);

                            el.remove();
                            eventBus.$emit('load-documents-list');
                            eventBus.$emit('delete-document', data);

                            swal.close();
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Документ удален',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400,
                            });
                        } catch(err) {
                            let response = err.response;
                            let message = 'При обработке запроса произошла ошибка на сервере';

                            if (response && response.status) {
                                if (response.status === 400) {
                                    message = response.data[0] || message;
                                }
                            }

                            this.$swal({
                                title: 'Произошла ошибка.',
                                text: message,
                                icon: 'error',
                                showConfirmButton: true,
                                confirmButtonText: 'Хорошо',
                            });

                            this.isLoading = false;
                        }
                    }
                }, reason => {
                    // отказ
                });
            },
            async modalDeleteRubric(rubric) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    this.$swal({
                        customClass: {
                            confirmButton: 'btn btn-lg btn-alt-success m-5',
                            cancelButton: 'btn btn-lg btn-alt-danger m-5'
                        },
                        title: 'Удалить рубрику?',
                        text: rubric.name,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Да, удалить!',
                        cancelButtonText: 'Отменить',
                    }).then(value => {
                        if (value.value) {
                            let swal = this.$swal({
                                title: 'Удаление...',
                                icon: 'info',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                            });
                            // выполнение
                            session
                                .delete('/api/v1/rubric_document/{ID}/'.replace('{ID}', rubric.id))
                                .then(response => {
                                    eventBus.$emit('load-documents-list');

                                    this.popRubric(rubric);
                                    this.previousRubric();

                                    swal.close();

                                    this.$swal({
                                        title: 'Отлично!',
                                        text: 'Рубрика удалена',
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1400,
                                    });
                                })
                                .catch(error => {
                                    this.$swal({
                                        customClass: {
                                            confirmButton: 'btn btn-lg btn-alt-success m-5',
                                            cancelButton: 'btn btn-lg btn-alt-danger m-5'
                                        },
                                        title: 'Внимание!',
                                        text: error.response.data.message,
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Да, удалить!',
                                        cancelButtonText: 'Отменить',
                                    }).then(value => {
                                        if (value.value) {
                                            let swal = this.$swal({
                                                title: 'Удаление...',
                                                icon: 'info',
                                                showConfirmButton: false,
                                                allowOutsideClick: false,
                                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                                            });
                                            // выполнение
                                            session
                                                .delete('/api/v1/rubric_document/{ID}/?always_delete=1'.replace('{ID}', rubric.id))
                                                .then(response => {
                                                    this.popRubric(rubric);
                                                    this.previousRubric();

                                                    swal.close();

                                                    this.$swal({
                                                        title: 'Отлично!',
                                                        text: 'Рубрика удалена',
                                                        icon: 'success',
                                                        showConfirmButton: false,
                                                        timer: 1400,
                                                    });
                                                })
                                                .catch((err) => {
                                                    this.errorHandler(err);
                                                });
                                        }
                                    }, reason => {
                                        // отказ
                                    });
                                });
                        }
                    }, reason => {
                        // отказ
                    });
                }
            },
            showRightBlock() {
                this.$emit('showRightBlock', true);
            },
            errorHandler(err) {
                this.$swal({
                    title: 'Ошибка!',
                    text: 'При обработке запроса произошла ошибка на сервере',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1400,
                });
                this.isLoading = false;
            },
            popDocument(document) {
                this.$emit('changeList', true);
                this.deleteDocument.push(document);
            },
            popRubric(rubric) {
                this.$emit('changeList', true);
                this.deleteRubric.push(rubric);
            },
            async onOpenDownloadExcel() {
                if (this.is_editor) {
                    this.$modal.show(DownloadExcelModal, {}, {
                        name: 'DownloadExcelModal',
                        adaptive: true,
                        maxWidth: 680,
                        width: '100%',
                        height: 'auto',
                    }, {
                        'before-close': this.onDownloadExcel
                    })
                } else {
                    session.get('api/v1/document/dump_documents/', { responseType: 'blob' })
                        .then(res => res.data)
                        .then((blob) => {
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');

                            link.href = url;
                            link.download = 'wikiworks_documents_test_status.xlsx';
                            link.click();
                        })
                }
            },

            async onDownloadExcel(data) {
                const params = data.params;

                if (params) {
                    let blob = {}

                    // user
                    if (params.type === 'user') {
                        blob = await session.get(`api/v1/document/dump_documents?user_id=${params.value}`, { responseType: 'blob' }).then(res => res.data)
                    // document
                    } else {
                        blob = await session.get(`api/v1/document/${params.value}/dump_users/`, { responseType: 'blob' }).then(res => res.data)
                    }

                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');

                    link.href = url;
                    link.download = 'wikiworks_documents_test_status.xlsx';
                    link.click();
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    @mixin shadow-block {
        box-shadow: 0 1px 3px #e4e7ed;
    }

    .doc-base {
        $bn: &;
        padding-bottom: 24px;

        &-header {
            padding: 10px 15px;
            @include shadow-block;
        }

        &-title {
            &__row {
                margin: -5px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__col {
                margin: 5px;
                &--left {
                    max-width: calc(100% - 100px);
                }
            }
        }

        &-name {
            color: #000;
            font-weight: 600;
            font-size: 1.25rem;
            display: flex;
            align-items: center;
            &__back {
                margin: 0 10px 0 0;
                .fa {
                    color: #000;
                }

                @include below(475px) {
                    display: none;
                }
            }

            &__more {
                fill: #000;
                cursor: pointer;
                margin-left: 10px;
                &:hover {
                    fill: #ffb71c;
                }
            }

            span {
                overflow: hidden;
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &-actions {
            gap: 8px;
            display: flex;
            align-items: center;
        }

        &-action {
            padding: 0 !important;
            height: 36px !important;
            width: 36px !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__main {
            margin: 30px 0 0;
        }

        &-block {
            @include shadow-block;
            & + #{$bn}-block {
                margin: 30px 0 0;
            }

            &--documents {
                margin: 30px 0 0;
            }

            &__header {
                padding: 10px 8px;
                background-color: #fbfbfb;
            }

            &__footer {
                padding: 10px 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__box {
            display: flex;
            flex-direction: column;
            padding: 10px 15px;
            & + #{$bn}__box {
                border-top: 1px solid #f0f2f5;
            }
        }

        &__list {
            &--sub {
                padding-left: 20px;
            }

            #{$bn}-caption + & {
                margin-top: 10px;
            }
        }

        &__spinner {
            margin: 20px 0 0;
            &--search {
                top: 0;
                right: 0;
                left: var(--aside-width);
                bottom: 0;
                position: fixed;

                z-index: 999;
                margin: 0;
                width: inherit !important;

                display: flex;
                align-items: center;
                justify-content: center;

                background-color: #bbbbbb7a;
            }
        }

        &__add {
            color: #ffb71c;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 13px;
            text-align: center;

            padding: 21px;
            cursor: pointer;
            display: block;
            background-color: #fff;
            &:hover {
                color: #fff;
                background-color: #ffb71c;
            }
        }

        &__more {
            display: inline-flex;
            justify-content: center;
            margin: 0 auto;
            max-width: 120px;
            color: #a4a4a4;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 13px;
            background-color: #f8f8f8;
            &:hover {
                background-color: #ececec;
            }

            &--sub {
                margin-top: 12px;
            }
        }

        &-empty {
            display: flex;
            height: 447px;
            margin-top: 1px;
            background-color: #fff;
            &__content {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin: auto;
                max-width: 480px;
            }

            &__icon {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &__text {
                font-size: 1rem;
                line-height: 20px;
                text-align: center;
                margin: 22px 0 0;
                p {
                    margin: 0;
                }
            }
        }

        &-filter {
            bottom: 0;
            position: sticky;
            z-index: 999;
            display: flex;
            align-items: center;
            padding: 0 43px 0 25px;
            background-color: #fff;
            border-top: 1px solid #f0f2f5;

            @include shadow-block;
            &__row {
                margin: -5px;
                display: flex;
                align-items: center;
            }

            &__item {
                color: $black;
                font-size: 1rem;
                line-height: 12px;
                position: relative;
                cursor: pointer;
                margin: 5px;
                padding: 25px 0;
                &:hover {
                    fill: $gold-main;
                    color: $gold-main;
                }
            }
        }
    }

    .one-rubric {
        &__popover {
            border-radius: 10px;
            border: none !important;
            box-shadow: 1px 1px 24px 4px rgba(38, 38, 38, 0.3);
        }
    }

    .header__main {
        &-add-popover {
            .popover {
                &-inner {
                    padding: 0 !important;
                }

                &-arrow {
                    display: none !important;
                }
            }

            &-inner {
                a {
                    color: #000;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 20px;
                    position: relative;
                    display: inline-block;
                    padding: 16px 16px 15px 51px;
                    border-bottom: 1px solid #D8D8D8;
                }
            }

            &-icon {
                top: 0;
                left: 0;
                position: absolute;
                width: 51px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-add {
            display: flex;
            justify-content: center;
            height: 36px;
            width: 36px;
            cursor: pointer;
            border-radius: 100px;
            background-color: $gold;
            padding: 0;
            border: none;
        }
    }
</style>

